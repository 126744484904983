import { BUYBACK_BASE_URL } from '@lambda/gateways/src/urls';
import {
  BuybackQuoteUS,
  BuybackSalesChannel,
} from '@/components/buyback/common';
import { logger } from '../logger';
import { analytics } from './vendor/segment/segment';
import { SegmentEvent } from './events';

const getUrls = (quote: BuybackQuoteUS) => {
  if (!quote.orderId) return {};
  const BASE_URL =
    process.env.STAGE === 'prod'
      ? BUYBACK_BASE_URL
      : `${BUYBACK_BASE_URL}/buyback`;

  return {
    accept_url: `${BASE_URL}/orders/${quote.orderId}/accept-counter-offer`,
    reject_url: `${BASE_URL}/orders/${quote.orderId}/reject-counter-offer`,
    label_url: `${BUYBACK_BASE_URL}/order/us/shipping-label/${quote.token}`,
  };
};

export const trackQuoteEvent = (
  event: SegmentEvent,
  quote: BuybackQuoteUS & { salesChannel: BuybackSalesChannel },
  callback?: () => void,
) => {
  try {
    logger.info({ event, quote }, 'Tracking quote event in Segment');

    const deviceIssues = Object.entries(quote.deviceIssues)
      .filter(([, value]) => value === true)
      .map(([key]) => key);

    const urls = getUrls(quote);

    const unlocked =
      quote.unlockedStatus?.toLowerCase().indexOf('unlocked') !== -1;

    const carrier = unlocked ? 'Unlocked' : quote.unlockedStatus;

    analytics.track(
      event,
      {
        id: quote.id,
        order_id: quote.orderId,
        amount: quote.finalQuote,
        image: quote.image,
        vendor: quote.vendorName,
        device: quote.deviceName,
        brand: quote.brand,
        carrier,
        category: quote.category,
        condition: quote.condition,
        housing: quote.housing,
        screen: quote.screen,
        storage: quote.storage,
        ram: quote.ram,
        cpu: quote.cpu,
        watch_case_material: quote.watchCaseMaterial,
        connectivity: quote.connectivity,
        device_functional: quote.deviceFunctional,
        battery_health: quote.batteryHealth,
        unlocked,
        device_issues: deviceIssues,
        other_issues: quote.otherIssues,
        accessories: quote.originalAccessories,
        payment_method: quote.paymentMethod,
        accept_url: urls.accept_url,
        reject_url: urls.reject_url,
        store: 'reebelo-us',
        shipping: quote.shippingDetails
          ? {
              amount: quote.shippingDetails?.totalCharges,
              tracking_number: quote.shippingDetails?.trackingNumber,
              tracking_url: quote.shippingDetails?.trackingLink,
              label_url: urls.label_url,
            }
          : null,
        sales_channel: quote.salesChannel,
      },
      undefined,
      callback,
    );
  } catch (error: any) {
    logger.error({ error, event }, 'Error tracking quote event in Segment');
  }
};
